

console.log('Vite ⚡️ Rails')

import '@hotwired/turbo-rails'
import 'foundation-sites'

import $ from 'jquery'; //=> ATTENTION: jQuery has to be imported after foundation. Foundation would not need this, it would be satisfied with the settings in vite.config.js
window.$ = $;

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});
function initPage() {
    $(document).foundation();
    console.log('foundation ready');
}